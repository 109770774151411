<template>
  <div :class="{ card: !isCompactMode }">
    <div :class="{ 'card-body': !isCompactMode }">
      <div v-if="groupedNotifications.length" class="list-group list-group-flush">
        <div
          v-for="(group, groupIdx) in groupedNotifications"
          :key="`key-${groupIdx}`"
          :class="isCompactMode ? 'small' : ''"
          class="list-group-item"
        >
          <div class="small text-muted">{{ group.date | dateReadableLong }}</div>
          <div
            v-for="(notification, notificationIdx) in group.items"
            :key="`key-${notificationIdx}`"
            class="d-flex align-items-center my-1"
          >
            <span
              v-if="!isCompactMode && !notification.isRead"
              class="status bg-danger flex-shrink-0 me-3"
            ></span>
            <div @click="handleClick" v-html="formatMessage(notification)"></div>
          </div>
        </div>
      </div>

      <div v-else class="small text-muted">You're all up to date</div>
    </div>

    <router-link
      v-if="isCompactMode && notificationItems.length > compactModeSize"
      to="/notification"
      class="btn btn-sm btn-outline-primary mt-3"
    >View all notifications</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default {
  name: 'NotificationList',
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('notification', ['notificationItems']),
    groupedNotifications() {
      const { notificationItems, isCompactMode, compactModeSize } = this;
      const groups = [];
      const items = isCompactMode ? notificationItems.slice(0, compactModeSize) : notificationItems;
      items.forEach((item) => {
        const date = dayjs.utc(item.createdAt).local();
        const dateString = date.format('YYYYMMDD');
        const bucket = groups.find((group) => group.dateString === dateString);
        if (!bucket) {
          groups.push({
            dateString,
            date,
            items: [item],
          });
        } else {
          bucket.items.push(item);
        }
      });
      return groups;
    },
    isCompactMode() {
      return this.mode === 'compact';
    },
  },
  methods: {
    handleClick(e) {
      const { target } = e;
      if (target.href && target.tagName === 'A') {
        const url = new URL(target.href);
        const to = url.pathname;
        if (window.location.pathname !== to && e.preventDefault) {
          e.preventDefault();
          this.$router.push(to);
        }
      }
    },
    formatMessage(notification) {
      const { message, createdAt } = notification;
      const tm = dayjs.utc(createdAt).local().format('HH:mm');
      return `${tm} ${message}`;
    },
  },
  data() {
    return {
      compactModeSize: 5,
    };
  },
};
</script>

<style lang="scss" scoped>
.status {
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}
</style>
